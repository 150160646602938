import React from "react";
import { NavLink } from "react-router-dom";
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessIcon from '@mui/icons-material/Business';

const Footer = (props) => {
  return (
    <>
      <div className="footer-page">
        <div className="footer-wrap">
          <div className="footer-title">
            <span className="footer-lable">Products</span>
            <ul>
            <li>
          <NavLink to="./XbcLa">Low aging crystal resonators of XBC-LA series.</NavLink>
            </li> 
            <li>
              <NavLink to="./XbcLn">Low noise low g-sensitivity crystal resonators of XBC-LN series.</NavLink>
            </li>         
            <li>
              <NavLink to="./osho_models">Low power OCXO models</NavLink>
            </li> 
            <li>
              <NavLink to="./rtcc22">High Precision Low Power Clocks.</NavLink>
            </li>     
            </ul>
          </div>

          <div className="footer-title">

            <div className="footer-contacts__record">
            <div className="footer-contacts__record--left"><span>About</span></div> 
            <NavLink to="./about" className="footer-title__about">About Us</NavLink>
            </div>  

            <div className="footer-contacts__record">
            <div className="footer-contacts__record--left">
              <AlternateEmailIcon color="primary" mailto="mailto:no-reply@example.com" />
            </div>  
              <a href="mailto:xball@art-timing.com ">xball@artxtal.com</a>
            </div>  

            <div className="footer-contacts__record">
            <div className="footer-contacts__record--left">
              <PhoneForwardedIcon color="primary" />
            </div>
            <a href="tel:+972 533 49 3831">+7 (705) 987-0483</a>  
            </div>  

            <div className="footer-contacts__record">
            <div className="footer-contacts__record--left">
              <BusinessIcon color="primary" />
            </div>  
            <span>Respublika Kazakhstan, Z01F8H4 (010000), Astana city, Almaty region, 101 st, buld. 12</span>
            </div>  

          </div>

          <div className="footer-rights"></div>
        </div>
      </div>
    </>
  );
};

export default Footer;
