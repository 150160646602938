//@@@ https://mui.com/material-ui/react-stepper/#text-with-carousel-effect @@@@
import {imgHttpFolder} from '../../redux/globalVariable'
import React from 'react';
import AboutCarousel from './AboutCarousel'

const About = () => {
console.log(`${imgHttpFolder}/about/01.jpeg`)

return (
  <>
    <div className="service__maincontent-wrap">
      <div className="service__maincontent--width">
        <p className="home-intro">
        Art Xtal Ltd. has been created by Israel Xtal Ball Enterprise for large scale manufacturing of  advanced frequency control and timing products including high precision crystal resonators, high stability low power OСХOs and high accuracy master clocks. 
        </p>

        <div className="hello-article">
          <h4 className="hello-article__title">
          Precision matters{" "}
            <span></span>
          </h4>

          <p className="about-article__content">
          Production facilities of Art Xtal Ltd. located in the Industrial park of Astana, Kazakhstan comprise equipment for manufacturing crystal resonator from crystal blank stage, assembling the OCXOs and clock modules, automated adjustment and testing benches as well as comprehensive information system for full traceability of the products from the ordering stage through all the manufacturing procedures to delivery to the customers.
          </p>

          <AboutCarousel />

          <p className="about-article__content">
          Owing to extraordinary technical performances  of Art Xtal products combined with small sizes and low power consumption  they are now unsurpassed solution for under-water geological exploration, high-end portable radio and GPS,  instrumentation and other modern applications, especially, with the battery supply.
            </p>

        </div>

      </div>
    </div>
  </>
);
}

export default About;
