import React from "react";
import GenModel from "./GenModel/GenModel";
import FilterFrequencyType from "./GenFilter/FlterFrequencyType";
import FilterTemperatureRange from "./GenFilter/FlterTemperatureRange";
import StateSpecFormShowHelper from "../Helper/StateSpecFormShowHelper";
import {
  servAgingPerDayArraySet, servAgingPerDayDefArraySet, servAgingPerDayMinArraySet,
  servContinuousCurrentArraySet, servSelectedModelStabilityVsTemperature
 } from '../../service/handleStep1'
 
import {servRecordtApiID, servRecordLocation,
 } from '../../service/serviseApi'
/* https://mui.com/material-ui/react-radio-button/#api */


import { useState } from "react";
import Modal2 from '../Modal/Modal2'

const GenModelsHello = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState();

  const decodePictureData = idModel =>{
    const selectedModel = props.gen_models.models.filter((value, index) => {
      //console.log(JSON.stringify(value.id, null, 2))
      return value.id === idModel;
      //return value.id === "5"
    })[0];

    setModalData(selectedModel)

  }

  //Modal window processing
  const handleClickGeneratorPicture = (idModel) => {
    //console.log('Modal clicked: ' + idModel)
    decodePictureData(idModel)
    setIsOpen(true)
  }

  const handleClickSelectedModel = (idModel) => {
    const selectedModel = props.gen_models.models.filter((value, index) => {
      //console.log(JSON.stringify(value.id, null, 2))
      return value.id === idModel;
      //return value.id === "5"
    })[0];
 
  const selectedModelStabilityVsTemperature = servSelectedModelStabilityVsTemperature(props.gen_models, idModel) 
  const continuousCurrentArraySet = servContinuousCurrentArraySet(props.gen_models, idModel)     
  const agingPerDayArraySet =  servAgingPerDayArraySet(props.gen_models, idModel)  
  const agingPerDayDefArraySet = servAgingPerDayDefArraySet (props.gen_models, idModel)
  const agingPerDayMinArraySet = servAgingPerDayMinArraySet (props.gen_models, idModel)
  
    //promiss
  servRecordtApiID(props.stSpecForm.api.getIDresponse, props.apiGetID)
  servRecordLocation(props.stSpecForm.api.getLocationResponse, props.apiGetLocation)

  /* ==== ID Received ==== */ 

   //apiGetID

    props.specFormInitStep2TC(
      selectedModel,
      selectedModelStabilityVsTemperature,
      continuousCurrentArraySet,
      agingPerDayArraySet,
      agingPerDayDefArraySet,
      agingPerDayMinArraySet,
      props.gen_models.filterTemperatureRange
    );
  };

  const genModel = props.gen_models.models.map((x, index) => {
    return (
      <GenModel
        key={x.id}
        genModelId={x.id}
        genModelTitle={x.name}
        genModelFrequencyRange={x.frequencyRange}
        genModelPackaging={x.packaging}
        genModelPicture={"./pimages/types200/" + x.pictureTag + "_200px.png"}
        genModelIsActive={x.isActive}
        genModelTemperatureRange={x.temperatureRangeSelected}
        genModelStabilityLimit={x.stabilityLimit}
        specFormInitStep2TC={x.specFormInitStep2TC}
        handleClickSelectedModel={handleClickSelectedModel}
        handleClickGeneratorPicture = {handleClickGeneratorPicture}
      />
    );
  });
  /* const modelsTemperatureDataArray */
  return (
    <>
      {isOpen && <Modal2 
                  setIsOpen={setIsOpen} 
                  showModal={true}
                  modalData={modalData}
                  />}           
      <div className="gen-models-filter__wrap">
        <h2>Define your main requirements</h2>
        <div className="gen-models-filter">
          <div className="gen-models-filter__title">
            <span>Frequency Type</span>
          </div>
          <div className="gen-models-filter__source">
            <FilterFrequencyType
              filterFrequencyTypeTC={props.filterFrequencyTypeTC}
            />
          </div>
        </div>
        <div className="gen-models-filter">
          <div className="gen-models-filter__title">
            <span>Temperature Range</span>
          </div>
          <div className="gen-models-filter__source">
            <FilterTemperatureRange
              filterTemperatureRangeTC={props.filterTemperatureRangeTC}
              temperatureRangeArray={props.gen_models.temperatureRangeArray}
            />
          </div>
        </div>

        {/* {temperatureRange()} */}
      </div>
      <div className="gen-models__wrap">
        <h2>Low Power OCXO Models</h2>
        <div className="gen-models">{genModel}</div>
      </div>

      <StateSpecFormShowHelper stSpecForm={props} />
    </>
  );
};

export default GenModelsHello;
