//***********  SpecificationBannerStep1.jsx **** */
import React from "react";
import "./SpecRezonators.scss";
import StateSpecFormShowHelper from "../Helper/StateSpecFormShowHelper";
//import { imgPathXbcXls } from "../../redux/globalVariable";
import Xls10La from "./Xls10La"
import Xls100La from "./Xls100La"
import { connect } from "react-redux";
import { compose } from "redux";
import {
  initXbc,
  setXbcTypeSpec,
  apiGetID,
  apiGetLocation
} from "../../redux/reducer-spec-form";
import {servRecordtApiID, servRecordLocation,
} from '../../service/serviseApi'

import SpecXbcLaBanner from "./SpecXbcLaBanner"
import SpecXbcL from "./SpecXbcL";
import MailSender from "./MailSender"

class ContainerXbcLa extends React.Component {

  state = {
    isDidMount: false
  };

  componentDidMount() {
    if (!this.state.isDidMount) {
    this.props.initXbc() //clear reducer's data
      servRecordtApiID(this.props.stSpecForm.api.getIDresponse, this.props.apiGetID)
      servRecordLocation(this.props.stSpecForm.api.getLocationResponse, this.props.apiGetLocation)
    }
  }

  render (){
    //console.log(this.props.stSpecForm.api.id)
    const clickHandler = (value) => {
      this.props.setXbcTypeSpec(value)
      //Collect info for reducer

    }

    const tmpComponent = () => {
      if (!this.props.stSpecForm.xbcTypeSpec) return <SpecXbcL
                        clickHandler10MHz = {()=>{clickHandler('10MHz')}}
                        clickHandler100MHz = {()=>{clickHandler('100MHz')}}
                        />
      if (this.props.stSpecForm.xbcTypeSpec === '10MHz') return <MailSender 
                        typeXls = {'Xls10La'} 
                        modelID = {this.props.stSpecForm.api.id}
                        api = {this.props.stSpecForm.api}/>
      if (this.props.stSpecForm.xbcTypeSpec === '100MHz') return <MailSender 
                        typeXls = {'Xls100La'} 
                        modelID = {this.props.stSpecForm.api.id}
                        api = {this.props.stSpecForm.api}/>                        
      
    }

    return(
      <div>
        <div className="speciication__wrap--position">
          <SpecXbcLaBanner />
        </div>
        {tmpComponent()}

      <StateSpecFormShowHelper stSpecForm={this.props.stSpecForm} />
    </div>

    )

  }
};


const mapStateToProps = (state) => {
  return {
    stSpecForm: state.specFormReducer,
  };
};

const mapDispatchToProps = {
  initXbc,
  setXbcTypeSpec,
  apiGetID,
  apiGetLocation
};

const ComplexContainerXbcLa = compose(
  connect(mapStateToProps, mapDispatchToProps),
)(ContainerXbcLa);

export default ComplexContainerXbcLa;
