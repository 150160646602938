import axios from "axios";

export const servRecordtApiID = async (httpStr, rxFnc) => {
     await axios
    .get(httpStr)
    .then((res) => {
      //console.log("+++End api+++");
      //console.log(res.data.id);
      rxFnc(res.data.id);
      return res.data.id;
    })
    .catch((error) => {
      console.log(error.message);
      //console.log("---End api---");
      return 0;
    });
};

export const servRecordLocation = async (httpStrGetData, rxFnc) => {
     await axios
    .get(httpStrGetData)
    .then((res) => {
      //console.log("+++start api+++");
      //console.log(res.data);
      rxFnc(res.data);
      return res.data;
    })
    .catch((error) => {
      console.log(error.message);
      console.log("---End api---");
      return 0;
    });
};


export const servPostLocation = async (api) => {
  //console.log(api.postLocationResponse)
  //console.log(api.location)
    await axios
   .post(api.postLocationResponse, api.location)
   .then((res) => {
     //console.log("+++End api+++");
     //console.log(res.data);
     //rxFnc(res.data.id);
     return res.data.id;
   })
   .catch((error) => {
     console.log(error.message);
     console.log("---End api---");
     return 0;
   });
};


export const servPostSubmData = async (dataForm) => {
  //console.log(dataForm.api.postSubmDataResponse)
  const article = {...dataForm.submitData, order_id: dataForm.api.id}
  //console.log(article)
    await axios
   .post(dataForm.api.postSubmDataResponse, article)
   .then((res) => {
     //console.log("+++End api+++");
     //console.log(res.data);
     //rxFnc(res.data.id);
     return res.data.id;
   })
   .then()
   .catch((error) => {
     console.log(error.message);
     console.log("---End api---");
     return 0;
   });
};