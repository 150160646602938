import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useState } from "react";
//import "../../assets/scss/menu.scss";
/* import "../../assets/scss/style.scss"; */
//import "../../assets/scss/style.scss";
//import styles from "./Modal2.module.css";
import styles from "./Modal2.scss";
import SpecificationBannerModal from "./SpecificationBannerModal"

/* function ModalDialog({ setIsOpen, showModal }) { */
function ModalDialog(props) {
  const [isShow, setIsShow] = useState(false)
  //const [isShow, setIsShow] = useState(false)
  const initModal = () => {
    return setIsShow(!false)
  }
  //if (dd === 1) setIsShow(true)
  //console.log(isShow)
  return (
    <>
{/*       <Button variant="success" onClick={initModal}>
        Open Modal
      </Button> */}
      {/* <Modal show={isShow}> */}
      <Modal show={(props.showModal === true)? true: false}>
        <Modal.Header closeButton onClick={() => props.setIsOpen(false)}>
          <Modal.Title></Modal.Title>

        </Modal.Header>
        <Modal.Body>
        <SpecificationBannerModal 
                        selectedModel={props.modalData?props.modalData:''}
                        features={props.modalData?props.modalData.features:''}
                />

        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => props.setIsOpen(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default ModalDialog