//***********  SpecificationBannerStep1.jsx **** */
import React from "react";
import "./SpecRezonators.scss";
import { imgPathXbc } from "../../redux/globalVariable";


const SpecXbcLn = (props) => {
  return (
    <div className="speciication__wrap--position">
      <div className="speciication-model__head-title">
      Low noise low g-sensitivity crystal resonators of XBC-LN series.
      </div>

      <div className="speciication-selected-banner_wrap--decor">
        <div className="speciication-model__title-name">
          <h1>XBC-LN</h1>
        </div>
        <div className="speciication-model__title-frequency">
          <p>10-100 MHz</p>
        </div>
        <div className="speciication-model__info-wrap">
          <div className="speciication-model__picture">
            <img src={imgPathXbc} />
          </div>
          <div className="speciication-model__package">
            <span>Packaging: </span>TO-8 (HC-37/u)
          </div>

          <div className="speciication-model__features">
            <h4>Features:</h4>
            <h5>Crystal cut: SC or modified SC</h5>
            <h5>Turn-over temperatures: 50-120 C</h5>
            <h5>Phase-noise limit:</h5>
            <h5>at 10 MHz: -123 dBc/Hz @ 1 Hz offset</h5>
            <h5>at 100 MHz: -110 dBc/Hz @10 Hz offset</h5>
            <h5>Vibration sensitivity: 0.2 ppb/g, 0-2000 Hz </h5>
            <h5>Low aging rate: to 0.2 ppb/day (10 MHz)</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecXbcLn;
