//**** Home.jsx  */
import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    document.title = 'Xtal Ball Technologies';
  }, []);
  return (
    <>
    
      <div className="service__maincontent-wrap">
        <div className="service__maincontent--width">
          <p className="home-intro">
          Art Xtal Ltd. is a world leader in manufacturing of  advanced frequency control and timing products. Basing on own unique technologies being result of the 30 years experience in this field we are producing precision crystal resonators, high stability OCXOs and high accuracy master clocks possessing extraordinary combination of their technical performances. 
          </p>


          <div className="hello-article">
            <h4 className="hello-article__title">
            Low aging crystal resonators of XBC-LA series.
            </h4>
            <div className="hello-article__collage">
              <div className="collage__circle--large">
                {" "}
                <img
                  src="./pimages/types200/type15_200px.png"
                  alt="XBC-LA series"
                />
              </div>
            </div>
            <p className="hello-article__content">
            The superb long-term stability of these devices is result of minimization of all the aging factors, first of all, mechanical stresses in the crystal mounting structure and adsorption of residual contaminations in the resonator volume. Operating at 10 MHz on the fifth overtone these crystals provide very low aging rate, to 5E-11/day, that is the level of the miniature atomic references.
            </p>
            <div className="hello-article__more">
            <NavLink to="./XbcLa">Load spec example to edit and order</NavLink>
            </div>
          </div>

          <div className="hello-article">
            <h4 className="hello-article__title">
            Low noise low g-sensitivity crystal resonators of XBC-LN series.
            </h4>
            <div className="hello-article__collage">
              <div className="collage__circle--large">
                {" "}
                <img
                  src="./pimages/types200/type15_200px.png"
                  alt="XBC-LN series"
                />
              </div>
            </div>
            <p className="hello-article__content">
            These resonators are manufactured with special low noise  technology which minimizes contribution of the resonator flicker factor into the oscillator phase-noise to - 123 dBc/Hz at 1 Hz offset for 10 MHz operation frequency and to - 110 dBc /Hz at 10 Hz 
Usage of optimal mountain structure enables very low sensitivity of the resonator frequency to the acceleration, down to 2E-10/g for the worst direction. 
Combination of both excellent performances enables the resonators very attractive usage in the low-noise OCXOs operating in the severe mechanical conditions.
            </p>
            <div className="hello-article__more">
            <NavLink to="./XbcLn">Load spec example to edit and order</NavLink>
            </div>
          </div>          

          <div className="hello-article">
            <h4 className="hello-article__title">
              Low Power High Stability Oven Control Crystal Oscillators{" "}
              <span>(LP OCXO)</span>
            </h4>
            <div className="hello-article__collage">
              <div className="collage__circle">
                {" "}
                <img src="./pimages/types200/type1_200px.png" alt="OCXO pict" />
              </div>
              <div className="collage__circle">
                {" "}
                <img src="./pimages/types200/type2_200px.png" alt="OCXO pict" />
              </div>
              <div className="collage__circle">
                {" "}
                <img src="./pimages/types200/type3_200px.png" alt="OCXO pict" />
              </div>
              <div className="collage__circle">
                {" "}
                <img src="./pimages/types200/type4_200px.png" alt="OCXO pict" />
              </div>
            </div>
            <p className="hello-article__content">
              L
              <span>ow Power Oven Control Crystal Oscillators (LP OCXOs).</span>{" "}
              These extraordinary frequency references are built on the
              Internally Heated Resonators (IHR) integrating in the vacuum
              holder the crystal plate with miniature heating structure. Recent
              achievements in the IHR technology and usage of sophisticated
              oscillator circuitry provide to the OCXOs as high as{" "}
              <span>0.005 ppb/˚C</span> frequency stability, as low as{" "}
              <span>0.05 ppb/day</span> aging, less than <span>150 mW</span>{" "}
              consumption, very low phase-noise level and{" "}
              <span>DIP8 or DIP14</span> compatible sizes. Owing to the
              extraordinary performances the LP OCXOs have become unreplaceable
              part of a variety of portable or/and battery fed equipment such as
              high-end mobile radio, mobile radars, synthesizers, precision
              clocks, GPS navigation, portable instrumentation and others.
            </p>

            <div className="hello-article__more">
              <NavLink to="/osho_models">Select your OCXO model</NavLink>
              {/* <a href="#">Select your OCXO model</a> */}
            </div>
          </div>

          <div className="hello-article">
            <h4 className="hello-article__title">
              High Precision Low Power Real-Time Clock/Calendar
            </h4>
            <div className="hello-article__collage">
              <div className="collage__circle--large">
                {" "}
                <img
                  src="./pimages/types200/type9_200px.png"
                  alt="Real-Time Clock/Calendar"
                />
              </div>
            </div>
            <p className="hello-article__content">
              Basing on the LP OCXOs operating at 8.192 MHz or 16.384 MHz
              frequency these clocks ensure very high precision of 1 s time
              intervals at very low power consumption and small sizes. Owing to
              the very attractive combination of properties these clocks are now
              very demanded part of different modern equipment with battery
              supply, for instance, of the underwater geological exploration
              equipment providing its long service-free period at high precision
              of the geological data.
            </p>
            <div className="hello-article__more">
            <NavLink to="./rtcc22">Load spec example to edit and order</NavLink>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Home;
