//***********  SpecificationBannerStep1.jsx **** */
import React from "react";
import "./SpecRezonators.scss";


const SpecXbcL = (props) => {
  return (
    <div className="speciication__wrap--position">

      <button className="specification-download-btn" role="button"  
       onClick={props.clickHandler10MHz} 
       >
          <span className="text">Specification &nbsp;10 MHz</span></button>

      <button className="specification-download-btn" role="button" 
      onClick={props.clickHandler100MHz} 
      >
          <span className="text">Specification 100 MHz</span></button>    

    </div>
  );
};

export default SpecXbcL;
