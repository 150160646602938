//Service For Xls table reperesentation
export const  sStyle = () => {
    const style = {
      screenWidth: window.innerWidth,
      tableWidth: {width: 300},
      pdfOffset: 0,
      pdfWidth: window.innerWidth,
      margin: 10
    }
    const tableWidthMin = 300
    const tableWidthMax = 650
    //console.log('screen width: ' + window.innerWidth)
  
    if (style.screenWidth > tableWidthMin) { 
      style.tableWidth.width = style.screenWidth - style.margin
      if (style.screenWidth > tableWidthMax) {
        style.tableWidth.width = tableWidthMax - style.margin
      }
    }
  
    style.pdfOffset = (style.screenWidth - style.tableWidth.width)/9
    style.pdfWidth = 0.6 * style.tableWidth.width * (style.screenWidth / style.tableWidth.width)
  
    return style
  }