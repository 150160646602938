import React from "react";
import HeaderHero from './HeaderHero'
import HeaderMenu from './HeaderMenu/HeaderMenu'

const Header = (props) => {
  //debugger


  return (
    <header className="header">
      <HeaderMenu  />
      <HeaderHero />
    </header>
  );
};


export default Header;
