import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";


const HeaderMenu = (props) => {

  const [isChecked, setIsChecked] = useState(false);

  return (
    <section className="top-nav">
      <div className="top-nav-logo-name">
        <img style={{height: '60px', padding: '10px'}}
          src={require("../../../assets/img/logo/logo1.png")}
          alt=""
          className="top-nav-logo"
        />
        <div className="top-nav-name">Art Xtal Ltd.</div>
      </div>
      <input id="menu-toggle" type="checkbox" 
        checked={isChecked}
        onChange={() => setIsChecked((prev) => !prev)}
        />
      <label className="menu-button-container" htmlFor="menu-toggle">
        <div className="menu-button"></div>
      </label>
      <ul className="menu" onClick={() => setIsChecked((prev) => !prev)} >
      <li>
          <NavLink to="./">Home</NavLink>
        </li>
        <li>
          <NavLink to="./XbcLa">Low aging crystal resonators of XBC-LA series.</NavLink>
        </li> 
        <li>
          <NavLink to="./XbcLn">Low noise low g-sensitivity crystal resonators of XBC-LN series.</NavLink>
        </li>         
        <li>
          <NavLink to="./osho_models">Low power OCXO models</NavLink>
        </li> 
        <li>
          <NavLink to="./rtcc22">High Precision Low Power Clocks.</NavLink>
        </li>      
        <li>
          <NavLink to="./about">About Us</NavLink>
        </li>  
        {/* <li>Contacts</li> */}
      </ul>
    </section>
  );
};

export default HeaderMenu;
