//***********  SpecificationStep1.jsx **** */
import React, { useEffect, useState }  from 'react';
import { imgPathXbcXls } from "../../redux/globalVariable";
//import ReportTemplate from "./Xls10La"
import Xls10La from "./Xls10La"
import Xls100La from "./Xls10La"
import Xls10Ln from "./Xls10La"
import Xls100Ln from "./Xls10La"
import html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
import {sStyle} from '../../service/decorStyle' //function calculate the sizes of screen

/* =====End cmponents ======== */
import { useRef } from "react";
import jsPDF from "jspdf";

class SpecificationLoader extends React.Component {
  constructor(props) {
    super(props);
    this.reportTemplateRef = React.createRef();
  }
  
  state = {
    isDidMount: false
  };

  ssStyle = () => sStyle() // Create the function from service object

  reportTemplate = () => {
    if (this.props.typeXls === 'Xls10La') return <Xls10La decor = {this.ssStyle().tableWidth} modelID = {this.props.modelID} />
    if (this.props.typeXls === 'Xls100La') return <Xls100La decor = {this.ssStyle().tableWidth} modelID = {this.props.modelID} />
    if (this.props.typeXls === 'Xls10Ln') return <Xls10Ln decor = {this.ssStyle().tableWidth} modelID = {this.props.modelID} />
    if (this.props.typeXls === 'Xls100Ln') return <Xls100Ln decor = {this.ssStyle().tableWidth} modelID = {this.props.modelID}/>
  }

  componentDidMount() {
    /* Send data Up to  SpecificationMailSender */
    if (!this.state.isDidMount) {
      const htmlXlsData = ReactDOMServer.renderToStaticMarkup(
        this.reportTemplate()
      );

      //console.log(this.ssStyle())
      this.props.handleHtmlXlsForm(htmlXlsData)
    }
    
    this.state.isDidMount = true;
  }

  handleGeneratePdf = () => {
    window.html2canvas = html2canvas;
    const doc = new jsPDF({
      format: "a4",
      unit: "pt"
    });


    doc.html(this.reportTemplateRef.current, {
      async callback(doc) {
         doc.save("document");
      },
      x: this.ssStyle().pdfOffset,
      y: 20,
      width: this.ssStyle().pdfWidth, //target width in the PDF document
      windowWidth: this.ssStyle().screenWidth //window width in CSS pixels
    });

  };
  
  

  render() {


    return <>
         <div className="spec-pdf__doc-wrap">

        <button className="specification-download-btn" role="button" onClick={this.handleGeneratePdf} >
          <span className="text">
          Save specification on your device</span></button>
        {/* <p>{`screen: ${this.ssStyle().screenWidth}  tableWidth: ${this.ssStyle().tableWidth.width} pdfOffset: ${this.ssStyle().pdfOffset}`}</p> */}
        <div ref={this.reportTemplateRef} >
          {this.reportTemplate()}
          
          <div className="pinout__wrap">
          
            <div className="xlsPinoutTable">
            <h4>Pin Specification</h4>
              <table>
                <tbody>
                <tr><th>Pin #</th><th>Name</th></tr>
                <tr><td className="xlsLeft">1</td><td>GND (Case)</td></tr>
                  <tr><td className="xlsLeft">2</td><td>XTAL</td></tr>
                  <tr><td className="xlsLeft">3</td><td>NC</td></tr>                        
                  <tr><td className="xlsLeft">4</td><td>XTAL</td></tr>
                  <tr><td className="xlsLeft">5</td><td>NC</td></tr>  
                  </tbody>
              </table>
            </div>

            <img className='xlsPinoutImage' style={{width: '300px'}} src={imgPathXbcXls} alt="Image" />
        </div>
        <hr />
        </div>
        </div>
    </>
  }

}



//export default ContactForm
export default SpecificationLoader