//***********  SpecificationBannerStep1.jsx **** */
import React from "react";
import "./SpecificationBannerStep1.scss"
import {imgPathClockTimerUsfr22} from '../../redux/globalVariable'

const SpecificationTimer = (props) => {
  

let myArray = []
const extractFeatures = (a) => {
  //window.bc = myArray
  if (a) {
    return a.map((x, index) => {
      return <h5 key={index}>{x}</h5>;
    })
  }
}
//window.bb = extractFeatures(props.features)

      return (
        

        <div className="speciication__wrap--position">
              <div className="speciication-model__head-title">
               Ultra stable Low Noise Low Power Frequency Reference
              </div>
          <div className="speciication-selected-banner_wrap--decor">
            
            <div className="speciication-model__title-name">
              <h1>XB-USFR-22</h1>
            </div>
            <div className="speciication-model__title-frequency">
              <p></p>
            </div>
            <div className="speciication-model__info-wrap">
              <div className="speciication-model__picture">
                <img src={imgPathClockTimerUsfr22} />
              </div>
              <div className="speciication-model__package">
                <span>Packaging: </span>35.56x45.72x15.6mm
              </div>

              <div className="speciication-model__features"></div>
            </div>
          </div>

          <div className="service__maincontent-wrap">
            <div className="service__maincontent--width">
              <div className="hello-article">
            <div className="timer-features__list">

                              
<h5 id="brief_specification">Output Frequencies</h5>
<div className="level5">
<ul>
<li className="level1 node"><div className="li">10 MHz, 1PPS
(Other frequencies are available)</div>
</li></ul>
</div>

<h5 id="brief_specification">Input Frequencies</h5>
<div className="level5">
<ul>
<li className="level1 node"><div className="li">10MHz, 1PPS </div>
</li></ul>
</div>



<h5 id="free_running_stability_10mhz">Free running stability 10MHz:</h5>
<div className="level5">
<ul>
<li className="level1"><div className="li"> Holdover</div>
</li>
<li className="level1 node"><div className="li"> 0..+50°C:</div>
<ul>
<li className="level2"><div className="li"> 1.1 uS for 1 hour</div>
</li>
<li className="level2"><div className="li"> 35 uS for 24 hours</div>
</li>
</ul>
</li>
<li className="level1 node"><div className="li"> -40..+85°C:</div>
<ul>
<li className="level2"><div className="li"> 2 uS for 1 hour</div>
</li>
<li className="level2"><div className="li"> 52 uS for 24 hours</div>
</li>
</ul>
</li>

<li className="level1 node"><div className="li">Temperature stability::</div>
<ul>
<li className="level2"><div className="li"> 0..+50°C: 0.3 ppb</div>
</li>
<li className="level2"><div className="li"> -40..+85°C: 0.5 ppb</div>
</li>
</ul>
</li>

<li className="level1 node"><div className="li"> Phase Noise (Typ):</div>
<ul>
<li className="level2"><div className="li"> -105dBc/Hz at 1Hz</div>
</li>
<li className="level2"><div className="li"> -137dBc/Hz at 10Hz</div>
</li>
<li className="level2"><div className="li"> -160dBc/Hz at 100Hz</div>
</li>
<li className="level2"><div className="li"> -165dBc/Hz at 1kHz</div>
</li>
<li className="level2"><div className="li"> -168dBc/Hz at 10kHz</div>
</li>
</ul>

</li>
<li className="level1 node"><div className="li"> Allan Deviation (Typ):</div>
<ul>
<li className="level2"><div className="li"> 3e-12 /1s</div>
</li>
<li className="level2"><div className="li"> 14e-12 /10s</div>
</li>
<li className="level2"><div className="li"> 120e-12 /100s</div>
</li>
</ul>
</li>
</ul>

</div>

<h5 id="power_supply">Power Supply:</h5>
<div className="level5">
<ul>
<li className="level1"><div className="li"> Voltage: 5 V</div>
</li>

<ul>
<li className="level2"><div className="li">Consumption current: &lt;50mA steady state</div>
</li>
<li className="level2"><div className="li"> (at 25°C for -40..+85°C working range)</div>
</li>
</ul>

</ul>

</div>

<h5 id="time_constant">PLL time constant (BW):</h5>
<div className="level5">
<ul>
<li className="level1 node"><div className="li"> 10MHz ref:</div>
<ul>
<li className="level2"><div className="li"> 1s/10s/100s/1000s manual limit selection</div>
</li>
</ul>
</li>
<li className="level1 node"><div className="li"> 1PPS ref:</div>
<ul>
<li className="level2"><div className="li"> 100s - 28h auto selection</div>
</li>
</ul>
</li>
</ul>

</div>

<h5 id="brief_specification">Power On to Lock time:</h5>
<div className="level5">
<ul>
<li className="level1 node"><div className="li">5 min. maximum</div>
</li></ul>
</div>

                <h5 id="power_supply">Block Diagram:</h5>
                <div className="level-img__wrap">
                <img className="level-img" src="./pimages/timer/usfr22-bdiagram.jpeg" alt="rtcc22-bdiagram" />                  
                </div> 
                <br />
                <h5 id="power_supply">Dimension Connections</h5>
                <div className="level-img__wrap">
                <img className="level-img" src="./pimages/timer/usfr22-bsize.jpeg" alt="rtcc22-bdiagram" /> 
                </div>
            </div>    

              </div>
            </div>
          </div>
        </div>
      );
  }


export default SpecificationTimer