//***********  SpecificationBannerStep1.jsx **** */
import React from "react";
import "./SpecRezonators.scss";
import { imgPathXbc } from "../../redux/globalVariable";
import Xls10La from "./Xls10La"

const SpecXbcLa = (props) => {
  return (
    <>   
      <div className="speciication-model__head-title">
      Low aging crystal resonators of XBC-LA series.
      </div>

      <div className="speciication-selected-banner_wrap--decor">
        <div className="speciication-model__title-name">
          <h1>XBC-LA</h1>
        </div>
        <div className="speciication-model__title-frequency">
          <p>10-100 MHz</p>
        </div>
        <div className="speciication-model__info-wrap">
          <div className="speciication-model__picture">
            <img src={imgPathXbc} />
          </div>
          <div className="speciication-model__package">
            <span>Packaging: </span>TO-8 (HC-37/u)
          </div>

          <div className="speciication-model__features">
            <h4>Features:</h4>
            <h5>Crystal cut: SC or modified SC</h5>
            <h5>Turn-over temperatures: 50-120 C</h5>
            <h5>Aging rate (at 60℃):</h5>
            <h5>at 10 MHz: to 0.05 ppb/day, 1 ppb/month</h5>
            <h5>at 100 MHz: to 1 ppb/day, 10 ppb/month</h5>
          </div>

        </div>
      </div>
    </>
  );
};

export default SpecXbcLa;
