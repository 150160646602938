import React from 'react';

const StateSpecFormShowHelper = (props) => {
    return (
        <>
        {/* <h1>Hello  JSON Show!</h1>
        <div><pre>{JSON.stringify(props, null, 2) }</pre></div>; */} 
        </>
    )
}

export default StateSpecFormShowHelper