import React, {useState}  from 'react';
import { Field, reduxForm } from 'redux-form'
import Test3 from './Xtest3'

const Xtest =() => {
    return (
        <>
        <Xls100La />
        
        </>
    )
}

const Xls100La = (props) => {
  const {decor} = props
  return (
    <div>
      <div className="spec-pdf__doc--size" style={decor} >
        <h2>Specification 100</h2>

        <table frame="VOID" cellSpacing={0} cols={10} rules="NONE" border={0}>
        <colgroup><col width={62} /><col width={222} /><col width={72} /><col width={100} /><col width={157} /><col width={50} /><col width={50} /><col width={49} /><col width={80} /><col width={100} /></colgroup>
        <tbody>
          <tr>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={4} width={456} height={18} align="LEFT">Model: XBC-LA-0002</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={6} width={485} align="RIGHT">Rev. 05.12.2022</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={40} align="CENTER" valign="MIDDLE">#</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">Crystal<br />Specification</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">Conditions</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={3} align="CENTER" valign="MIDDLE">Value</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Unit</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER"><font color="#000000">Included in <br />the test data </font></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={18} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Min.</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Typ.</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Max.</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="LEFT"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={31} align="CENTER" valign="MIDDLE" sdval="1.1" sdnum="9;">1.1</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Nominal Frequency, FL(1)</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} rowSpan={2} align="CENTER" valign="MIDDLE">at ref.Temperature = TBD(2)<br />CL = TBD(2)<br />Drive Level = 0.7 mA</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={3} align="CENTER" valign="MIDDLE" sdval={100000000} sdnum="9;0;#,##0;-#,##0">100,000,000</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Hz</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={31} align="CENTER" valign="MIDDLE" sdval="1.2" sdnum="9;">1.2</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Initial Tolerance</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">±2</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">ppm</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={18} align="CENTER" valign="MIDDLE" sdval="1.3" sdnum="9;">1.3</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Cut</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={3} align="CENTER" valign="MIDDLE"> Modified SC</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="LEFT"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={18} align="CENTER" valign="MIDDLE" sdval="1.4" sdnum="9;">1.4</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Overtone Order</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={3} align="CENTER" valign="MIDDLE">5rd</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="LEFT"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={40} align="CENTER" valign="MIDDLE" sdval="1.5" sdnum="9;">1.5</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Turnover Point (available range)</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">50<br />86</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">78<br />110</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">°C</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={21} align="CENTER" valign="MIDDLE" sdval="1.6" sdnum="9;">1.6</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Equivalent Series Resistance, R1</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={62} sdnum="9;">62</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={76} sdnum="9;">76</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Ohm</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={20} align="CENTER" valign="MIDDLE" sdval="1.7" sdnum="9;">1.7</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Q-factor, Q</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={130} sdnum="9;">130</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">x1000</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={20} align="CENTER" valign="MIDDLE" sdval="1.8" sdnum="9;">1.8</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Motional Inductance, L1</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={13} sdnum="9;">13</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">15.5</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">mH</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={20} align="CENTER" valign="MIDDLE" sdval="1.9" sdnum="9;">1.9</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Static (shunt) Capacitance, C0</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">2.8</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">pF</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={23} align="CENTER" valign="MIDDLE">1,10</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Motional Capacitance, C1</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">0.18</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">fF</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={10} height={18} align="LEFT" valign="MIDDLE"><b>             Frequency stability</b></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={40} align="CENTER" valign="MIDDLE" sdval="2.1" sdnum="9;">2.1</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE"><b> G-sensitivity(3)</b></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">worst axis, 0 – 2 kHz vibration freq.</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">±1</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">ppb/G</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} rowSpan={2} height={40} align="CENTER" valign="MIDDLE" sdval="2.2" sdnum="9;">2.2</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} rowSpan={2} align="LEFT" valign="MIDDLE"><b>SSB Phase noise</b></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">10 Hz</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={-100} sdnum="9;">-100</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} rowSpan={2} align="CENTER" valign="MIDDLE">dBc/Hz</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">100 Hz</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={-130} sdnum="9;">-130</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} rowSpan={2} height={37} align="CENTER" valign="MIDDLE" sdval="2.3" sdnum="9;">2.3</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} rowSpan={2} align="LEFT" valign="MIDDLE"><b>Aging</b></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE">per day</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} rowSpan={2} align="CENTER" valign="MIDDLE">after 30 days of operation</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">±1</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">ppb</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE">first year</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">±200</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">ppb</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={10} height={18} align="LEFT"><b>Maximum ratings, environmental, mechanical conditions.</b></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={19} align="LEFT" valign="MIDDLE">Operating Temperature Range</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE"><font color="#000000">-60°C to +110°C</font></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={19} align="LEFT" valign="MIDDLE">Storage Temperature Range</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE"><font color="#000000">-60°C to +110°C</font></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={18} align="LEFT" valign="MIDDLE">Mechanical Shock</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE">Per MIL-STD-202G, 150G, 2ms</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={18} align="LEFT" valign="MIDDLE">Vibration</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE">Per MIL-STD-202G, 10G swept sine 0 to 2000Hz</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={18} align="LEFT" valign="MIDDLE">Soldering Conditions</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE">Hand solder only – not reflow compatible 260°C 10s (on pins)</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} rowSpan={2} height={37} align="LEFT" valign="MIDDLE">Washing Conditions</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE">Washing with water or alcohol based detergent allowed only with final enough drying stage</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE"><b>Ultrasonic cleaning could cause degradation of properties</b></td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};


const Xls10La = (props) => {
  const {decor} = props
  return (
    <div>
      <div className="spec-pdf__doc--size" style={decor} >
        <h2>Specification</h2>

      <table frame="VOID" cellSpacing={0} cols={10} rules="NONE" border={0}>
        <colgroup><col width={62} /><col width={222} /><col width={72} /><col width={100} /><col width={157} /><col width={50} /><col width={50} /><col width={49} /><col width={81} /><col width={100} /></colgroup>
        <tbody>
          <tr>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={4} width={457} height={18} align="LEFT" bgcolor="#FFFFFF">Model: XBC-LA-0001</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={6} width={486} align="RIGHT">Rev. 05.12.2022</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={40} align="CENTER" valign="MIDDLE">#</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">Crystal<br />Specification</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">Conditions</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={3} align="CENTER" valign="MIDDLE">Value</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Unit</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><font color="#000000">Included in <br />the test data </font></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={18} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Min.</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Typ.</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Max.</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={31} align="CENTER" valign="MIDDLE" sdval="1.1" sdnum="9;">1.1</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Nominal Frequency, FL(1)</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} rowSpan={2} align="CENTER" valign="MIDDLE">at ref.Temperature = TBD(2)<br />CL = TBD(2)<br />Drive Level = 0.5 mA</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={3} align="CENTER" valign="MIDDLE" sdval={10000000} sdnum="9;0;#,##0;-#,##0">10,000,000</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Hz</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={31} align="CENTER" valign="MIDDLE" sdval="1.2" sdnum="9;">1.2</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Initial Tolerance</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">±0.7</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">ppm</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={18} align="CENTER" valign="MIDDLE" sdval="1.3" sdnum="9;">1.3</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Cut</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={3} align="CENTER" valign="MIDDLE">SC</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={18} align="CENTER" valign="MIDDLE" sdval="1.4" sdnum="9;">1.4</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Overtone Order</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={3} align="CENTER" valign="MIDDLE">5rd</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={40} align="CENTER" valign="MIDDLE" sdval="1.5" sdnum="9;">1.5</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Turnover Point (available range)</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">50<br />95</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">85<br />110</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">°C</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={21} align="CENTER" valign="MIDDLE" sdval="1.6" sdnum="9;">1.6</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Equivalent Series Resistance, R1</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={210} sdnum="9;">210</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={280} sdnum="9;">280</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">Ohm</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={20} align="CENTER" valign="MIDDLE" sdval="1.7" sdnum="9;">1.7</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Q-factor, Q</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={1200} sdnum="9;">1200</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">x1000</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={20} align="CENTER" valign="MIDDLE" sdval="1.8" sdnum="9;">1.8</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Motional Inductance, L1</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={4600} sdnum="9;">4600</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={5300} sdnum="9;">5300</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">mH</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={20} align="CENTER" valign="MIDDLE" sdval="1.9" sdnum="9;">1.9</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Static (shunt) Capacitance, C0</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">1.0</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">pF</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={23} align="CENTER" valign="MIDDLE">1,10</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE">Motional Capacitance, C1</td>
            <td style={{borderTop: '1px solid #000000', borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">0.05</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">fF</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE">+</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={10} height={18} align="LEFT" valign="MIDDLE"><b>             Frequency stability</b></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} height={40} align="CENTER" valign="MIDDLE" sdval="2.1" sdnum="9;">2.1</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="LEFT" valign="MIDDLE"><b> G-sensitivity(3)</b></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">worst axis, 0 – 1 kHz vibration freq.</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">±0.7</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">ppb/G</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} rowSpan={2} height={40} align="CENTER" valign="MIDDLE" sdval="2.2" sdnum="9;">2.2</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} rowSpan={2} align="LEFT" valign="MIDDLE"><b>SSB Phase noise</b></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">1 Hz</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={-100} sdnum="9;">-100</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} rowSpan={2} align="CENTER" valign="MIDDLE">dBc/Hz</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} align="CENTER" valign="MIDDLE">10 Hz</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE" sdval={-130} sdnum="9;">-130</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} rowSpan={2} height={37} align="CENTER" valign="MIDDLE" sdval="2.3" sdnum="9;">2.3</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} rowSpan={2} align="LEFT" valign="MIDDLE"><b>Aging</b></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE">per day</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} rowSpan={2} align="CENTER" valign="MIDDLE">after 30 days of operation</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">±0.05</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">ppb</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE">first year</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">±10</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} align="CENTER" valign="MIDDLE">ppb</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} align="LEFT" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={10} height={18} align="LEFT"><b>Maximum ratings, environmental, mechanical conditions.</b></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={19} align="LEFT" valign="MIDDLE">Operating Temperature Range</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE"><font color="#000000">-60°C to +110°C</font></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={19} align="LEFT" valign="MIDDLE">Storage Temperature Range</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE"><font color="#000000">-60°C to +110°C</font></td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={18} align="LEFT" valign="MIDDLE">Mechanical Shock</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE">Per MIL-STD-202G, 150G, 2ms</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={18} align="LEFT" valign="MIDDLE">Vibration</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE">Per MIL-STD-202G, 10G swept sine 0 to 2000Hz</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} height={18} align="LEFT" valign="MIDDLE">Soldering Conditions</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE">Hand solder only – not reflow compatible 260°C 10s (on pins)</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000'}} colSpan={2} rowSpan={2} height={37} align="LEFT" valign="MIDDLE">Washing Conditions</td>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE">Washing with water or alcohol based detergent allowed only with final enough drying stage</td>
          </tr>
          <tr>
            <td style={{borderBottom: '1px solid #000000', borderLeft: '1px solid #000000', borderRight: '1px solid #000000'}} colSpan={8} align="LEFT" valign="MIDDLE"><b>Ultrasonic cleaning could cause degradation of properties</b></td>
          </tr>
          <tr>
            <td colSpan={10} height={18} align="CENTER" valign="MIDDLE"><br /></td>
          </tr>
          <tr>
            <td colSpan={10} height={75} align="LEFT" valign="MIDDLE">(1) FL=FR*(1+C1/(2*(CL + C0)))<br />FR — series resonance frequency<br />FL — load resonance frequency<br />CL — load capacitance</td>
          </tr>
          <tr>
            <td colSpan={10} height={19} align="LEFT" valign="MIDDLE">(2) To be determined by customer.</td>
          </tr>
          <tr>
            <td colSpan={10} height={19} align="LEFT" valign="MIDDLE"><font face="Times New Roman">(3) For a lower value, please consult the factory.</font></td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};






class NameForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {value: ''};
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
      console.log(this.state.value);
    }
  
    handleSubmit(event) {
      alert('A name was submitted: ' + this.state.value);
      //event.preventDefault();
    }
  
    render() {
      return (
        <form onSubmit={this.handleSubmit}>
          <label>
            Name:
            <input type="text" value={this.state.value} onChange={this.handleChange} />
          </label>
          <input type="submit" value="Submit" />
        </form>
      );
    }
  }


  function Checkbox() { 
  
    const [checked, setChecked] = useState(false); 
    const handleChange = () => { 
      
      setChecked(!checked); 
      
    }; 
    
    return ( 
      
      <div> 
      
         
          
        <p>
          {checked ? 'Checked' : 'Not checked'}
        </p> 
      
      </div> 
      
    ); 
    
  }; 
  
export default Xtest