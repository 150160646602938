
export const servAgingPerDayMinArraySet = (gen_models, idModel) => {
  const agingPerDayMinArraySet = gen_models.temperatureRange
    .filter((value, index) => {
      return value.range === gen_models.filterTemperatureRange;
    })[0]
    .modelsTemperatureData.filter((value, index) => {
      return value.modelId === idModel;
    })[0].agingPerDayMin;

  return agingPerDayMinArraySet;
};

export const servAgingPerDayDefArraySet = (gen_models, idModel) => {
  const agingPerDayDefArraySet = gen_models.temperatureRange
    .filter((value, index) => {
      //console.log(JSON.stringify(value.id, null, 2))
      return value.range === gen_models.filterTemperatureRange;
    })[0]
    .modelsTemperatureData.filter((value, index) => {
      return value.modelId === idModel;
    })[0].agingPerDayDef;

  return agingPerDayDefArraySet;
};

export const servAgingPerDayArraySet = (gen_models, idModel) => {
  const agingPerDayArraySet = gen_models.temperatureRange
    .filter((value, index) => {
      //console.log(JSON.stringify(value.id, null, 2))
      return value.range === gen_models.filterTemperatureRange;
    })[0]
    .modelsTemperatureData.filter((value, index) => {
      return value.modelId === idModel;
    })[0].agingPerDay;

  return agingPerDayArraySet;
};

export const servContinuousCurrentArraySet = (gen_models, idModel) => {
    const continuousCurrentArraySet = gen_models.temperatureRange
      .filter((value, index) => {
        //console.log(JSON.stringify(value.id, null, 2))
        return value.range === gen_models.filterTemperatureRange;
      })[0]
      .modelsTemperatureData.filter((value, index) => {
        return value.modelId === idModel;
      })[0].continuousCurrent;
  
    return continuousCurrentArraySet;
  };
  
export const servSelectedModelStabilityVsTemperature = (gen_models, idModel) => {
    const selectedModelStabilityVsTemperature =
      gen_models.temperatureRange
        .filter((value, index) => {
          //console.log(JSON.stringify(value.id, null, 2))
          return value.range === gen_models.filterTemperatureRange;
        })[0]
        .modelsTemperatureData.filter((value, index) => {
          return value.modelId === idModel;
        })[0].stabilityVsTemperature;
  
    return selectedModelStabilityVsTemperature;
  };